import { appDetails } from "@helpers/const";
import { apollo } from "@helpers/providers/clients";
export const isBrowser: boolean = () => typeof window !== "undefined";

const authTokenKey = `${appDetails.name}_token`;

export const getToken: object = () =>
  isBrowser() && JSON.parse(localStorage.getItem(authTokenKey))?.token;
export const getUser: object = () =>
  isBrowser() && JSON.parse(localStorage.getItem(authTokenKey));

const setDetails = (user: object) =>
  localStorage.setItem(authTokenKey, JSON.stringify(user));

export const handleLogin: void = (res: object) => {
  const { accessToken } = res.login
    ? res.login
    : res.register
    ? res.register
    : res.ssoLogin;

  if (accessToken) {
    setDetails({
      token: accessToken,
    });
  }
};

export const isLoggedIn: boolean = () => {
  const token = getToken();
  return !!token;
};

export const logout: void = (callback: Function) => {
  apollo.cache.reset();
  localStorage.removeItem(authTokenKey);
  callback();
};
