import packageJson from "../../package.json";

export const PREFIXED_URI = "/app";

export const authSelection = {
  login: "login",
  signup: "signup",
};

export const providers = {
  google: "google",
  facebook: "facebook",
};

export const providersQuery = {
  google: "id_token",
  facebook: "access_token",
};

export const paths = {
  home: `/home`,
  access: `/access`,
  board: `/board`,
};

export const URLS = {
  home: `${PREFIXED_URI}${paths.home}`,
  login: `${PREFIXED_URI}${paths.access}`,
  board: `${PREFIXED_URI}${paths.board}`,
};

export const messages = {
  errors: {
    login: "ID or password mismatch",
    network: "Network Error",
  },
  success: {
    addCart: "Succesfully Added to Cart!",
    addWishlist: "Succesfully Added to Wishlist!",
  },
  others: {
    adding: "Adding..",
  },
};

export const appDetails = {
  name: packageJson.name,
  version: packageJson.version,
};
